.verify-container {
    margin-top: 50px;
    text-align: center;
}

.verify-input {
    margin-bottom: 20px;
}

.verify-button {
    margin-top: 20px;
}
