.landing-container {
    text-align: center;
}

.landing-title {
    margin-bottom: 1rem;
    color: #1976d2;
}

.landing-subtitle {
    margin-bottom: 2rem;
    color: #333;
}

.landing-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.landing-button {
    min-width: 150px;
}
@media (max-width: 600px) {
    .landing-buttons {
      flex-direction: column;
      align-items: center;
    }
    
    .landing-button {
      width: 100%; /* Full width on mobile */
      max-width: 300px; /* Optional max width to control size */
    }
  }